import moment from 'moment'

export const handleClick = (e: any, url: string) => {
    e.preventDefault();
    window.location.href = url
}

export const formatDate = (days: number) => {
    var data = new Date(); 
    data.setDate(data.getDate() + +days);
    return moment(data).format('DD/MM/YYYY')
}

export const getStorage = <T extends unknown>(key: string): T => {
    const data: any = localStorage.getItem(key)
    return JSON.parse(data)
}

export const setStorage = (key: string, value: any) => {
    localStorage.setItem(key, JSON.stringify(value))
}

export const isSameDate = (date: string): boolean => {
    const currentDate = new Date(); 
    const auxDate = moment(currentDate).format('DD/MM/YYYY')
    return moment(date).isSame(auxDate);
}

