import React from 'react'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'

import SignIn from '../../pages/signin';
import Home from '../../pages/home';
import { getStorage, isSameDate } from '../../utils';
import { User } from '../../domain/models/user';


const PrivateRoute = ({ children }: { children: JSX.Element }) => {
  let history = useNavigate();
  const user = getStorage<User>('@auth')
  if (isSameDate(user.validate)) {
    history('/')
  }
  return children
};


const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate replace to="/login" />} />
      <Route path="/login" element={<SignIn />} />
      <Route path="/home" element={
        <PrivateRoute>
          <Home />
        </PrivateRoute>} />
    </Routes>
  )
}

export default AppRoutes