import React, { useEffect, useState } from 'react'
import "./_card.scss"
import card from '../../assets/card.png'
import verso from '../../assets/verso.png'
import { getStorage } from '../../utils'
import Header from '../../components/commons/header'
import { User } from '../../domain/models/user'

const Card = () => {
    const [flip, setFlip] = useState(false)
    const [user, setUser] = useState(Object)


    useEffect(() => {
        const getUser = () => {
            const user = getStorage<User>("@auth")
            if (user) {
                setUser(user)
            }
        }
        return getUser()
    }, [])

    return (
        <div>
            <Header />
            <div className={`c_card_inner ${flip ? 'c_card_inner_flip' : ''}`} onClick={() => setFlip(!flip)}>
                <div className={`c_card_front`}>
                    <img alt="Frente" src={card} />
                    <div className="c_card_back_expire">
                        <label>Validade</label>
                        <span>{user?.validate || ""}</span>
                    </div>
                    <div className="c_card_back_name">
                        <label>Nome</label>
                        <span>{user?.name || ""}</span>
                    </div>
                    <div className="c_card_back_code">
                        <label>Código</label>
                        <span>{user?.code || ""}</span>
                    </div>
                </div>
                <div className={`c_card_back`}>
                    <img alt="Verso" src={verso} />
                </div>
            </div>
        </div>
    )
}
export default Card