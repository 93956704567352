import React from 'react'
import Page from '../../components/commons/page'

const Agreement = () => {
  return (
    <Page
      title='Guia de Descontos'
      frame={`https://allsul.com.br/app/credenciados/index.php?usuario_logado=albraga`}
    />
  )
}
export default Agreement
