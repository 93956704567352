import React from 'react'
import Page from '../../components/commons/page'

const Notices = () => {
  return (
    <Page
      title='Notícias'
      frame={`https://allsul.com.br/app/noticias.html`}
    />
  )
}
export default Notices
