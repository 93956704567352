import Client from "../infra/http/http-client"

const api = Client(String(process.env.REACT_APP_API_LOGIN))

export const SignInApi = async (login: string, senha: string) => {
  return await api.post('api', {
    login,
    senha
  }, { 
    headers: { 
      'Content-Type': 'application/json', 
      'timeout': '5000' 
    }
  })
}