import React from 'react'
import Header from '../header';
import "./_page.scss"

interface Props {
    frame?: string
    children?: any
    title?: string
}
const Page = ({ frame, children, title }: Props) => {

    return (
        <div>
            <Header title={title} />
            <div className={"d-flex flex-column w-100 page-custom"}>
                {frame ? <iframe title="All Sul Convênios" src={frame} frameBorder="0" scrolling="yes" sandbox="allow-scripts"></iframe> : children}
            </div>
        </div>
    )
}

export default Page
