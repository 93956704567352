import React from 'react'
import { Typography, Box } from '@mui/material'

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
const TabPanel = (props: TabPanelProps) => {
  const { children, value, index } = props;

  return (
    <Box
      role="container-panel"
      hidden={value !== index}
      id={`container-${index}`}
      aria-labelledby={`container-${index}`}
      maxWidth="xs">
      {value === index && (
        <Typography alignContent={"center"}>{children}</Typography>
      )}
    </Box>
  );
}
export default TabPanel