import { Box, CardMedia, List, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import RoomIcon from '@mui/icons-material/Room';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import React from 'react'
import Header from '../../components/commons/header'
import './_contacts.scss'
import map from '../../assets/map.png'

const Contacts = () => {
  return (
    <div>
      <Header title='Contatos' />
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="20vh"
        flexDirection="column"
      >
        <List
          sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >

          {/* Whats */}
          <ListItemButton href='https://api.whatsapp.com/send?phone=+55(41)99696-0706'>
            <ListItemIcon>
              <WhatsAppIcon style={{ "color": "#3a9e3a" }} />
            </ListItemIcon>
            <ListItemText primary="(41)99696-0706" />
          </ListItemButton>

          {/* Comercial */}
          {/* <ListItemButton href='tel:4131324007'>
            <ListItemIcon >
              <LocalPhoneIcon style={{ "color": "#235b9b" }} />
            </ListItemIcon>
            <ListItemText primary="(41)3232-4007" />
          </ListItemButton> */}

          {/* Email */}
          <ListItemButton href='mailto:convenios@allsul.net.br'>
            <ListItemIcon>
              <AlternateEmailIcon style={{ "color": "#519ef7" }} />
            </ListItemIcon>
            <ListItemText primary="convenios@allsul.net.br" />
          </ListItemButton>

          {/* Facebook */}
          <ListItemButton href='https://www.facebook.com/clubeallsul'>
            <ListItemIcon>
              <FacebookIcon style={{ "color": "#519ef7" }} />
            </ListItemIcon>
            <ListItemText primary="Clube All Sul" />
          </ListItemButton>

          {/* Instagram */}
          <ListItemButton href='https://www.instagram.com/redeallsul'>
            <ListItemIcon>
              <InstagramIcon style={{ "color": "#C13584" }} />
            </ListItemIcon>
            <ListItemText primary="@redeallsul" />
          </ListItemButton>

          {/* Endereço */}
          {/* <ListItemButton href='https://www.google.com.br/maps/place/Allsul+-+Rede+de+Descontos/@-25.4271855,-49.2692929,15z/data=!4m5!3m4!1s0x0:0x2d224740f715738d!8m2!3d-25.4271855!4d-49.2692929'>
            <ListItemIcon>
              <RoomIcon style={{ "color": "#f16464" }} />
            </ListItemIcon>
            <ListItemText primary="Rua Treze de Maio, 336 - CJ - Centro - Curitiba - CEP: 80020-270" />
          </ListItemButton> */}

          <CardMedia
            component="img"
            height="194"
            image={map}
            alt="Paella dish"
          />
          <Typography variant="body2" color="text.secondary">
            <ListItemButton href='https://www.google.com.br/maps/place/Allsul+-+Rede+de+Descontos/@-25.4271855,-49.2692929,15z/data=!4m5!3m4!1s0x0:0x2d224740f715738d!8m2!3d-25.4271855!4d-49.2692929'>
              <ListItemIcon>
                <RoomIcon style={{ "color": "#f16464" }} />
              </ListItemIcon>
              <ListItemText primary="Rua Treze de Maio, 336 - CJ - Centro - Curitiba - CEP: 80020-270" />
            </ListItemButton>
          </Typography>

        </List>
      </Box>
    </div>
  )
}
export default Contacts
