import { Typography } from '@mui/material'
import React from 'react'
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import "./_header.scss"
// import { useNavigate } from "react-router-dom";

interface Props {
    title?: string
}

const Header = ({ title }: Props) => {
    console.log(title)
    // const history = useNavigate()
    return (
        <div className="d-flex flex-fill c_header">
            {/* <div className="d-flex justify-content-center c_header_icon" onClick={() => history("/")}><ArrowBackIcon /></div> */}
            <div className="d-flex flex-fill flex-column justify-content-center align-items-center align-content-center c_header_label">
                <span>{title}</span>
            </div>
        </div>
    )
}
export default Header