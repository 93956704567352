import React, { useRef, useState } from 'react'
import './_home.scss'

import { Box, BottomNavigationAction, BottomNavigation, Paper, CssBaseline } from '@mui/material'

import CreditCardIcon from '@mui/icons-material/CreditCard';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import HomeIcon from '@mui/icons-material/Home';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import TabPanel from '../../components/commons/tab-panel';
import Card from '../card';
import Agreement from '../agreement';
import Notices from '../notices';
import Contacts from '../contacts';

const Home = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [idx, setIdx] = useState(0)

  const handleClick = (item: any) => {
    setIdx(item)
  }
  return (
    <Box sx={{ pb: 7 }} ref={ref}>
      <CssBaseline />

      <TabPanel index={0} value={idx}>
        <Card />
      </TabPanel>

      <TabPanel index={1} value={idx}>
        <Agreement />
      </TabPanel>

      <TabPanel index={2} value={idx}>
        <Notices />
      </TabPanel>

      <TabPanel index={3} value={idx}>
        <Contacts />
      </TabPanel>

      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
        <BottomNavigation
          showLabels
          value={idx}
          onChange={(_, newValue) => {
            handleClick(newValue);
          }}
        >
          <BottomNavigationAction label="Cartão" icon={<CreditCardIcon />} />
          <BottomNavigationAction label="Guia" icon={<AttachMoneyIcon />} />
          <BottomNavigationAction label="Notícias" icon={<HomeIcon />} />
          <BottomNavigationAction label="Contatos" icon={<PeopleAltIcon />} />
        </BottomNavigation>
      </Paper>
    </Box >
  )
}
export default Home
