import axios from 'axios';

const Client = (url: string ) => { 
  const api = axios.create({        
      baseURL: url
  }); 
  api.interceptors.request.use((config) => {
    console.log(config);
    return config
  }, err => Promise.reject(err))

  return api
}

export default Client
