import { Box, Button, FormHelperText, IconButton, InputAdornment, TextField } from '@mui/material'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import LogoHeader from '../../components/commons/logo'
import { SignInApi } from '../../services/login'
import { formatDate, handleClick, setStorage } from '../../utils'
import './_login.scss'

const SignIn = () => {
  const navigate = useNavigate()

  const [login, setFormLogin] = useState("")
  const [senha, setFormPassword] = useState("")
  const [showPasswd, setShowPasswd] = useState(false)

  const handleClickShowPassword = () => {
    setShowPasswd(!showPasswd);
  };

  const submitClick = async (e: any) => {
    e.preventDefault()

    try {
      const { data, status } = await SignInApi(login, senha)
      if (status === 200) {
        if (data?.validade > "0") {
          setStorage('@auth', {
            code: data?.codigo,
            login: data?.login,
            name: data?.nome,
            validate: formatDate(data?.validade)
          })
          navigate('/home')
        } else {
          alert("Não foi possível logar, favor entre em contato com Allsul!")
        }
      }
    } catch (error) {
      alert("Não foi possível fazer o login, favor tente novamente!")
    }
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="80vh"
      flexDirection="column"
    >
      <LogoHeader width="200" height="100" />
      <p></p>
      <Box display="flex" flexDirection="column">
        <TextField id="standard-login" label="Usuário" variant="standard" value={login} onChange={(event) => setFormLogin(event.target.value)} />
        <p></p>
        <TextField id="standard-password" label="Senha" type={showPasswd ? 'text' : 'password'} variant="standard" value={senha} onChange={(event: any) => setFormPassword(event.target.value)} InputProps={{
          endAdornment:
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                edge="end"
              >
                {showPasswd ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
        }} />
        <p></p>

        <Button variant="contained" onClick={(event: any) => submitClick(event)} color="error">Entrar</Button>
        <FormHelperText id="component-helper-text">
          Esqueceu os detalhes de seu login? <a href="http://allsul.com.br/app/senha.html">Clique aqui!</a>
        </FormHelperText>
        <p></p>
        <FormHelperText id="component-helper-text">
          Meu primeiro cadastro
        </FormHelperText>
        <Button variant="contained" onClick={(event: any) => handleClick(event, "http://allsul.com.br/app/desbloqueio.html")} color="error">CADASTRAR</Button>
      </Box>
    </Box>
  )
}

export default SignIn
